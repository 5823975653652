/*---------------------------------------*
  Product
*---------------------------------------*/
@import 'base/variables';

body {
  display: block !important;
}

.header__burger,
.header__utils,
.nav,
.footer {
  display: none;
}

.header__top {
  padding: 0;
}

.header .logo {
  margin: 0 auto;
  padding: 0;

  svg {
    width: 150px;
  }
}

.section {
  padding-top: $space;
  padding-bottom: $space;
}

.js-accordion {
  height: auto;
}
